export const getCurrentEstablishmentId = () => {
  const selectedEstablishment = localStorage.getItem('selectedEstablishment')
  if (selectedEstablishment) {
    return JSON.parse(selectedEstablishment).id
  }
  return null
}

export const checkPermissions = (permissions) => {
  const myPermissions = JSON.parse(localStorage.getItem('myPermissions'))
  if (!myPermissions) {
    return false
  }
  if (!permissions) {
    return true
  }
  return myPermissions.some((permission) => permissions.includes(permission))
}

