import { defineStore } from 'pinia'
import axios from '@/plugins/axios.js'
import { useToast } from 'vue-toastification'
import { useAuthStore } from './useAuthStore'
const toast = useToast()

export const useEstablishmentStore = defineStore('establishment', {
  state: () => ({
    establishment: {
      code: '',
      name: '',
      dependency: '',
      phone: '',
      commune: '',
      address: '',
      latitude: '',
      longitude: '',
      from_email: '',
      patient_app_is_active: false,
      subdomain: '',
      primary_color: '',
      secondary_color: '',
      logo: null,
      assets_zip: null,
      sendgrid_api_key: '',
    },
    establishments: [],
    selectedEstablishment: null,
    showBanner: false
  }),

  getters: {
    getSelectedEstablishment() {
      this.selectedEstablishment = JSON.parse(localStorage.getItem('selectedEstablishment'))
      return this.selectedEstablishment
    },
    getShowBanner() {
      this.showBanner = JSON.parse(localStorage.getItem('showBanner'))
      return this.showBanner
    }
  },

  actions: {
    async getEstablishments(params) {
      return axios
        .get('/establishment/establishment/', { params })
        .then((res) => {
          this.establishments = res.data.results
          return res
        })
        .catch((err) => {
          return err.response
        })
    },

    async getEstablishment(id) {
      return axios
        .get(`/establishment/establishment/${id}/`)
        .then((res) => res)
        .catch((err) => {
          return err.response
        })
    },

    async getCommune() {
      return axios
        .get('/establishment/commune/')
        .then((res) => res)
        .catch((err) => {
          return err.response
        })
    },

    async getRegions() {
      return axios
        .get('/establishment/region/')
        .catch((err) => {
          return err.response
        })
    },

    async getDependency() {
      return axios
        .get('/establishment/establishment-dependency-choices/')
        .then((res) => res)
        .catch((err) => {
          return err.response
        })
    },

    async createEstablishment(formData) {
      return axios
        .post('/establishment/establishment/', formData)
        .then((res) => {
          toast.success('Establecimiento creado correctamente')
          return res
        })
        .catch((err) => {
          toast.error('Error al crear establecimiento')
          return err.response
        })
    },

    async deleteEstablishment(id) {
      return axios
        .delete(`/establishment/establishment/${id}`)
        .then((res) => {
          toast.success('Establecimiento eliminado correctamente')
          return res
        })
        .catch((err) => {
          toast.error('Error al eliminar establecimiento')
          return err.response
        })
    },

    async updateEstablishment(establishment, id) {
      return axios
        .put(`/establishment/establishment/${id}/`, establishment)
        .then((res) => {
          toast.success('Establecimiento actualizado correctamente')
          return res
        })
        .catch((err) => {
          toast.error('Error al actualizar establecimiento')
          return err.response
        })
    },

    setSelectedEstablishment(establishment) {
      localStorage.setItem('selectedEstablishment', JSON.stringify(establishment))
      this.selectedEstablishment = establishment
      useAuthStore().refreshMyPermissions()
    },

    setShowBanner(showBanner) {
      localStorage.setItem('showBanner', JSON.stringify(showBanner))
      this.showBanner = showBanner
    },

    resetEstablishment() {
      this.establishment = {
        code: '',
        name: '',
        dependency: '',
        phone: '',
        commune: '',
        address: '',
        latitude: '',
        longitude: '',
        from_email: '',
        patient_app_is_active: false,
        subdomain: '',
        primary_color: '',
        secondary_color: '',
        logo: null,
        assets_zip: null,
      }
    }
  }
})
